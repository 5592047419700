export const constructInitMarketingData = (marketing_name='analytic_params', store_type='electronics') => {
  const marketingData = JSON.parse(localStorage.getItem(marketing_name));
  const params = []
  marketingData?.oid && params.push(`oid=${encodeURIComponent(marketingData.oid)}`);
  marketingData?.pid && params.push(`pid=${encodeURIComponent(marketingData.pid)}`);
  marketingData?.sid && params.push(`sid=${encodeURIComponent(marketingData.sid)}`);
  marketingData?.eid && params.push(`eid=${encodeURIComponent(marketingData.eid)}`);
  marketingData?.uid && params.push(`uid=${encodeURIComponent(marketingData.uid)}`)
  marketingData?.gclid && params.push(`gclid=${encodeURIComponent(marketingData.gclid)}`)
  marketingData?.hsid && params.push(`hsid=${encodeURIComponent(marketingData.hsid)}`);
  params.push(`store_type=${encodeURIComponent(store_type)}`);

  return params.join('&');
};
